import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import MobileApp1 from "../../components/sections/MobileApp1/MobileApp1";
import MobileApp2 from "../../components/sections/MobileApp2/MobileApp2";
import {getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToTechnologyButton from "../../components/shared/ButtonBackTo/BackToTechnologyButton";
import MobileApp3 from "../../components/sections/MobileApp3/MobileApp3";


const MobileApps = () => {
    const routes = getRoutes();
    const parentTitle = routes.Technology.pageTitle;
    const title = routes.TechnologyMobileApps.pageTitle;

    return (
        <Layout>
            <SEO title={title}/>

            <Breadcrumbs
                title={routes.TechnologyMobileApps.pageTitle}
                parentTitles={[parentTitle]}
                parentRoutes={[routes.Technology]}
            />

            <MobileApp3/>

            <MobileApp1/>

            <MobileApp2/>

            <BackToTechnologyButton/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default MobileApps;
